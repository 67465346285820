import { parseUnits } from '@ethersproject/units'
import { useTranslation } from '@pancakeswap/localization'
import { Token } from '@pancakeswap/sdk'
import { useToast } from '@pancakeswap/uikit'
import { transactionApi } from 'apis/transaction.api'
import { BigNumber } from 'ethers'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin, getContract } from 'utils'
import { useWeb3React } from '../../packages/wagmi/src/useWeb3React'
import DEPAY_ABI from '../config/abi/depay.json'
import { useCallWithMarketGasPrice } from './useCallWithMarketGasPrice'
import { useProviderOrSigner } from './useProviderOrSigner'

export const DEPAY_ADDRESS = '0xA36f224903ec54ca09c1f8B677E677917e0f97Cd' // TODO: move to config

export function useDepayDeposit() {
  const { account, isConnected } = useWeb3React()
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const { t } = useTranslation()
  const { toastError } = useToast()
  const providerOrSigner = useProviderOrSigner()
  const addTransaction = useTransactionAdder()

  const deposit = useCallback(
    async (token: Token, amount: number, description: string) => {
      const depayContract = getContract(DEPAY_ADDRESS, DEPAY_ABI, providerOrSigner)
      const amountInWei = parseUnits(amount.toString(), token.decimals)

      if (!account) {
        toastError(t('Error'), t('No account found'))
        return
      }

      if (!amount) {
        toastError(t('Error'), t('No amount specified'))
        return
      }

      const estimatedGas = await depayContract.estimateGas
        .deposit(token.address, token.decimals, amountInWei, description)
        .catch(() => {
          toastError(t('Error'), t('Failed to estimate gas'))
        })

      try {
        const txResponse = await callWithMarketGasPrice(
          depayContract,
          'deposit',
          [token.address, token.decimals, amountInWei, description],
          {
            gasLimit: calculateGasMargin(estimatedGas as BigNumber),
          },
        )

        addTransaction(txResponse, {
          summary: `Deposit ${amount} ${token.symbol}`,
          translatableSummary: {
            text: 'Deposit %amount% %symbol%',
            data: {
              amount,
              symbol: token.symbol,
            },
          },
          type: 'depay-deposit',
        })

        // eslint-disable-next-line consistent-return
        return txResponse
      } catch (error: any) {
        if (error?.code === 'ACTION_REJECTED') {
          await transactionApi.cancelTransactions({
            type: 'withdraw',
            tranId: description,
          })
          toastError('Giao dịch đã bị huỷ', description)
        }
      }
    },
    [account, callWithMarketGasPrice, t, toastError, isConnected, providerOrSigner],
  )

  return deposit
}
