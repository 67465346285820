import styled from 'styled-components'

interface Props {
  errors?: any
  name?: any
  message?: string | undefined
  icon?: any
}

function ErrorMessages({ errors, name, message, icon }: Props) {
  const logError = errors?.[name]

  return (
    <ErrorMess>
      {message && icon} {logError?.message || message || ''}
    </ErrorMess>
  )
}

const ErrorMess = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #fb1919;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: 0.2px;

  @media screen and (max-width: 425px) {
    font-size: 10px;

    & > svg {
      width: 14px;
      height: 13px;
    }
  }
`
export default ErrorMessages
