import { NativeCurrency, Token } from '@pancakeswap/sdk'
import { ChevronDownIcon, Flex, Text } from '@pancakeswap/uikit'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const DropDownHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  transition: border-radius 0.15s;
  @media screen and (max-width: 600px) {
    padding: 0 10px;
  }
`

const DropDownListContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 250px;
  margin-top: 1rem;
  padding: 12px;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  box-sizing: border-box;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.background};
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 50px;
    width: 100%;
  }
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number }>`
  display: flex:
  align-items: center;
  cursor: pointer;
  width: ${({ width }) => width}px;
  position: relative;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 12px;
  ${(props) =>
    props.isOpen &&
    css`
      ${DropDownHeader} {
        /* box-shadow: ${({ theme }) => theme.tooltip.boxShadow}; */
      }

      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border-top-width: 0;
        border: 1px solid ${({ theme }) => theme.colors.cardBorder};
        width: 100%;
        background: ${({ theme }) => theme.colors.background};
        top: 49px;
        border-radius: 8px;
      }
    `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (max-width: 375px) {
    svg {
      right: 5px;
    }
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;
  border-radius: 6px;
  &:hover {
    background: rgba(255, 255, 255, 0.06);
    > div {
      color: ${({ theme }) => theme.colors.text} !important;
    }
  }
`

export interface SelectProps {
  options: (Token | NativeCurrency)[]
  onChange?: (option: Token | NativeCurrency) => void
  defaultOptionIndex?: number
}

export interface OptionProps {
  token: Token
}

const Select: React.FunctionComponent<SelectProps> = ({ options, onChange, defaultOptionIndex }) => {
  const { push } = useRouter()
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    if (options.length > 1) {
      setIsOpen(!isOpen)
    }
    event.stopPropagation()
  }

  const onOptionClicked = (selectedIndex: number) => () => {
    setSelectedOptionIndex(selectedIndex)
    setIsOpen(false)

    if (onChange) {
      onChange(options[selectedIndex])
      push({ query: { from: 'VND', to: options[selectedIndex]?.symbol } })
    }
  }

  const itemsSelected = useMemo(() => {
    return options[selectedOptionIndex]
  }, [selectedOptionIndex, options])

  useEffect(() => {
    setContainerSize({
      width: dropdownRef.current.offsetWidth, // Consider border
      height: dropdownRef.current.offsetHeight,
    })

    const handleClickOutside = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  function replaceSpace(str: string) {
    return str.replace(/ /g, '')
  }
  return (
    <DropDownContainer isOpen={isOpen} ref={containerRef} {...containerSize}>
      <DropDownHeader onClick={toggling}>
        <Flex alignItems="center" style={{ gap: '8px' }}>
          <img
            src={replaceSpace(`/images/coins/${itemsSelected?.symbol?.toLowerCase()}.png`)}
            alt="token"
            style={{ width: '24px' }}
          />
          <Text fontSize={['12px', '', '16px']} textTransform="uppercase" color="#1A202C" fontWeight="800">
            {itemsSelected?.symbol}
          </Text>
        </Flex>
      </DropDownHeader>
      <ChevronDownIcon onClick={toggling} />
      <DropDownListContainer>
        <DropDownList ref={dropdownRef}>
          {options.map((option, index) =>
            index !== selectedOptionIndex ? (
              <CustomListItem onClick={onOptionClicked(index)} key={JSON.stringify(option)}>
                <Flex width="100%" justifyContent="flex-start" alignItems="center" style={{ gap: '12px' }}>
                  <img
                    src={replaceSpace(`/images/coins/${option?.symbol?.toLowerCase()}.png`)}
                    alt="token"
                    style={{ width: '24px' }}
                  />
                  <Text textTransform="uppercase" fontSize={[12, null, 14]}>
                    {option?.symbol}
                  </Text>
                </Flex>
              </CustomListItem>
            ) : null,
          )}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>
  )
}

export default Select

const CustomListItem = styled(ListItem)`
  @media screen and (max-width: 425px) {
    padding: 0px;
  }
`
