import { Flex, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { formatAmount } from 'utils/formatInfoNumbers'
import { useCalculateAmountReceived } from './DepositToBank'
import { CardExchangeRate, GridExchangeRate } from './style'

const ImageCustom = styled.div`
  width: 48px;
  height: 48px;

  @media screen and (max-width: 425px) {
    width: 34px;
    height: 34px;
  }
`

const ExchangeRate = () => {
    const usdtConvertVnd = useCalculateAmountReceived(1, 'USDT', 'VND')


    return (
        <Flex width="100%" height="auto">
            <GridExchangeRate>
                <CardExchangeRate>
                    <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                        <ImageCustom>
                            <img src="/images/depay/exhange_blue.png?version=1.0" alt="icon" />
                        </ImageCustom>
                    </Flex>
                    <Flex
                        width="100%"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="flex-start"
                        style={{ gap: '6px' }}
                    >
                        <Text fontSize={['10px', '', '12px']} color="#718096" fontWeight="500">
                            Giá trị quy đổi
                        </Text>
                        <Text fontSize={['12px', '', '16px']} color="#1A202C" fontWeight="800">
                            1 USDT tương đương {formatAmount(usdtConvertVnd || 0, { isNormal: true })} VND
                        </Text>
                    </Flex>
                </CardExchangeRate>
                <CardExchangeRate>
                    <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                        <ImageCustom>
                            <img src="/images/depay/exhange_green.png?version=1.0" alt="icon" />
                        </ImageCustom>
                    </Flex>
                    <Flex
                        width="100%"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="flex-start"
                        style={{ gap: '6px' }}
                    >
                        <Text fontSize={['10px', '', '12px']} color="#718096" fontWeight="500">
                            Giá trị quy đổi
                        </Text>
                        <Text fontSize={['12px', '', '16px']} color="#1A202C" fontWeight="800">
                            1 VND tương đương 1 điểm VNDC
                        </Text>
                    </Flex>
                </CardExchangeRate>
            </GridExchangeRate>
        </Flex>
    )
}
export default ExchangeRate
