import React from "react";
import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "../Button/Button";
import { BaseButtonProps, variants } from "../Button/types";
import { ButtonMenuItemProps } from "./types";

interface InactiveButtonProps extends BaseButtonProps {
  forwardedAs: BaseButtonProps["as"];
}

const InactiveButton: PolymorphicComponent<InactiveButtonProps, "button"> = styled(Button)<InactiveButtonProps>`
  background-color: transparent;
  border-radius: 40px 40px 0px 0px;
  color: ${({ theme, variant }) => (variant === variants.CUSTOM ? "#A0AEC0" : theme.colors.text)};
  &:hover:not(:disabled):not(:active) {
    background-color: transparent;
  }
`;
const CsButton = styled(Button)`
  border-radius: 40px 40px 0px 0px;
  color: #ffffff;
  background-color: #0436e7;

  @media screen and (max-width: 425px) {
    border-radius: 12px 12px 0px 0px;
  }
`;

const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, "button"> = ({
  isActive = false,
  variant = variants.CUSTOM,
  as,
  ...props
}: ButtonMenuItemProps) => {
  if (!isActive) {
    return <InactiveButton forwardedAs={as} variant={variant} {...props} />;
  }

  return <CsButton as={as} variant={variant} {...props} />;
};

export default ButtonMenuItem;
