import axios from 'axios'

const getExchangeRate = async (data: {
  amount: number
  from: string
  to: string
}): Promise<{
  amountReceived: number
  from: string
  to: string
  success: boolean
}> =>
  (
    await axios.get('exchange-rates', {
      params: data,
    })
  ).data
export const exchangeRateApi = {
  getExchangeRate,
}
