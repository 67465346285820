import { useToast } from '@pancakeswap/uikit'
import { SUPPORT_DEPAY } from 'config/constants/supportChains'
import { useContext, useEffect } from 'react'
import OneSignal from 'react-onesignal'
import withAuth from 'utils/withAuth'
import Home from 'views/Home'
import { LoginContext } from '../contexts/LoginContext'

const IndexPage = () => {
  const { toastSuccess, toastError } = useToast()
  const { user } = useContext(LoginContext)

  useEffect(() => {
    if (user) {
      OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true,
      }).then(() => {
        console.log('OneSignal Initialized')
        OneSignal.showHttpPrompt().then()
        OneSignal.showHttpPermissionRequest().then()
        OneSignal.isPushNotificationsEnabled(() => true).then((isEnabled) => {
          console.log('isEnabled: ', isEnabled)
        })
        OneSignal.setExternalUserId(user.userId).then(() => {
          console.log(`set external user id: ${user.userId}`)
        })
        OneSignal.on('notificationDisplay', function (event) {
          const { data } = event
          const { status, type, tranId } = data
          const types = ['topup', 'withdraw']
          if (types.includes(type)) {
            if (status === 'success') {
              toastSuccess('Giao dịch thành công', tranId)
            } else {
              toastError('Giao dịch thất bại', tranId)
            }
          }
        })
      })
    }
  }, [user])

  useEffect(() => {
    OneSignal.getExternalUserId().then(function (externalUserId) {
      console.log('externalUserId: ', externalUserId)
    })
    OneSignal.on('subscriptionChange', function (isSubscribed) {
      console.log("The user's subscription state is now:", isSubscribed)
    })
  }, [OneSignal])

  return <Home />
}

const IndexPageWithAuth = withAuth(IndexPage)

// @ts-ignore
IndexPageWithAuth.chains = SUPPORT_DEPAY

export default IndexPageWithAuth
