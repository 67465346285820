import { NativeCurrency, Token } from '@pancakeswap/sdk'
import {
  Button,
  ChevronDownIcon,
  Flex,
  InputGroup,
  SwapExchangeIcon,
  Text,
  useMatchBreakpoints,
  useModal,
  useToast,
} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Select from 'components/Select/SelectToken'
import { LoginContext } from 'contexts/LoginContext'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useDepayTokens from 'hooks/useDepayTokens'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import useTheme from 'hooks/useTheme'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { formatAmount } from 'utils/formatInfoNumbers'
import { getShortAccount } from 'utils/getShortAccount'
import { chains } from 'utils/wagmi'
import AlertChangeWalletModal from 'views/Signin/components/AlertChangeWalletModal'
import AlertErrorNetworkModal from 'views/Signin/components/AlertErrorNetworkModal'
import { exchangeRateApi } from '../../../apis/exchangeRate.api'
import { transactionApi } from '../../../apis/transaction.api'
import { PAGE } from '../../../constants/page.constants'
import Partner from './Partner'
import { CsNumericFormat } from './style'

interface Props {
  fillprice: string
  onChangeFillprice?: (newValue: string) => void
  account: string
}

const GridCustom = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  height: auto;
  @media screen and (max-width: 600px) {
    grid-template-columns: 60% 40%;
  }
`

const DepositFromBlockchain: React.FC<Props> = ({ fillprice, onChangeFillprice, account }) => {
  const { isMobile } = useMatchBreakpoints()
  const { theme } = useTheme()
  const { push } = useRouter()
  const { user }: any = useContext(LoginContext)
  const [amountReceived, setAmountReceived] = React.useState('0.00')
  const [selectedToken, setSelectedToken] = React.useState<Token | NativeCurrency>()
  const tokens = useDepayTokens()
  const { chainId } = useActiveChainId()
  const { pendingChainId, isLoading } = useSwitchNetwork()
  const toast = useToast()
  const [openWrongWalletModal] = useModal(
    <AlertChangeWalletModal title="Thông báo" subTitle="Vui lòng kiểm tra lại địa chỉ ví" />,
  )
  const [errorNetwork] = useModal(
    <AlertErrorNetworkModal title="Bị lỗi mạng" subTitle="Vui lòng kiểm tra kết nối của bạn và thử lại sau." />,
  )

  const foundChain = useMemo(
    () => chains.find((c) => c.id === (isLoading ? pendingChainId || chainId : chainId)),
    [isLoading, pendingChainId, chainId],
  )

  const handleAmountReceived = (value: string) => {
    if (value && +value >= 50000) {
      exchangeRateApi
        .getExchangeRate({
          amount: +value,
          from: 'VND',
          to: selectedToken?.symbol?.trim(),
        })
        .then((res) => {
          setAmountReceived(res.amountReceived.toString())
        })
        .catch((err) => {
          console.log(err)
          setAmountReceived('0.00')
        })
    } else {
      setAmountReceived('0.00')
    }
  }

  const handlePay = async () => {
    if (!navigator.onLine) {
      errorNetwork()
      return
    }
    if (fillprice && selectedToken && account) {
      transactionApi
        .createTopUpsIsPartner({
          amount: +fillprice,
          from: 'VND',
          to: selectedToken.symbol?.trim(),
          walletAddress: account,
          chainId: selectedToken.chainId,
        })
        .then(({ data }) => {
          const { id } = data.transaction
          push({
            pathname: PAGE.QR_CODE,
            query: {
              id,
            },
          })
        })
        .catch((err) => {
          console.log(err)
          const { code } = err.response.data
          if (code === 7005) {
            openWrongWalletModal()
          }
          if (code === 9001) {
            toast.toastError('Bạn không phải là đối tác')
          }
          if (code === 7004) {
            toast.toastError('Địa chỉ ví không khả dụng khi niêm yết')
          }
        })
    }
  }

  const handlePayByPartner = async (item?: any) => {
    if (!navigator.onLine) {
      errorNetwork()
      return
    }
    if (+fillprice < 50000) {
      onChangeFillprice('0')
      return
    }
    if (fillprice && selectedToken && account) {
      transactionApi
        .createTopUps({
          amount: +fillprice,
          from: 'VND',
          to: selectedToken.symbol?.trim(),
          walletAddress: account,
          chainId: selectedToken.chainId,
          bankBin: +item?.bankBin,
          accNo: +item?.accNo,
          accName: item?.accName,
          partnerId: item?.id,
        })
        .then(({ data }) => {
          const { id } = data.transaction
          push({
            pathname: PAGE.QR_CODE,
            query: {
              id,
            },
          })
        })
        .catch((err) => {
          const { code ,message} = err.response.data
          if (code === 7005) {
            openWrongWalletModal()
          }
          if (code === 7004) {
            toast.toastError('Địa chỉ ví không khả dụng khi niêm yết')
          }
          if (code === 7001) {
            toast.toastError(message)
        }
        })
    }
  }

  useEffect(() => {
    handleAmountReceived(fillprice)
  }, [fillprice, selectedToken])

  useEffect(() => {
    if (tokens.length > 0) {
      setSelectedToken(tokens[0])
    }
  }, [tokens])

  return (
    <Flex width="100%" flexDirection="column" style={{ gap: '12px' }}>
      <Flex width="100%" justifyContent="space-between" alignItems="center" margin="20px 0">
        <Flex alignItems="center" style={{ gap: '8px' }}>
          <Text fontWeight="800" fontSize={[13, null, 20]} color="#1A202C">
            Đổi điểm theo {selectedToken?.symbol} đã chọn - Mạng {foundChain?.name}
          </Text>
        </Flex>

        <Flex justifyContent="flex-end">
          <Link href="/transaction" passHref>
            <Flex alignItems="center" style={{ gap: '10px', cursor: 'pointer' }}>
              <SwapExchangeIcon width="28px" />
              <Text fontSize={['14px', '', '16px']} fontWeight="500" color="#194BFB">
                Lịch sử
              </Text>
            </Flex>
          </Link>
        </Flex>
      </Flex>
      <Flex width="100%">
        <Text color="#718096" fontSize={['10px', '', '14px']} fontWeight="800">
          Số tiền cần nạp
        </Text>
      </Flex>
      <Flex flexDirection="column">
        <Flex
          width="100%"
          border={`1px solid ${fillprice && +fillprice < 50000 ? '#FB1919' : theme.colors.cardBorder}`}
          borderRadius="12px"
        >
          <InputGroup
            endIcon={
              <Text fontSize="16px" color="#1A202C" fontWeight="500">
                VND
              </Text>
            }
            scale="lg"
          >
            <CsNumericFormat
              value={fillprice}
              decimalScale={2}
              thousandSeparator={!false}
              placeholder={fillprice.length === 0 ? 'Nhập số tiền muốn nạp' : fillprice}
              // eslint-disable-next-line no-return-assign, no-param-reassign
              onFocus={(e) => (e.target.placeholder = '')}
              onValueChange={(values) => {
                const { value } = values
                if (Number(value) >= 0) {
                  onChangeFillprice(value)
                }
              }}
              maxLength={35}
              allowNegative={false}
            />
          </InputGroup>
        </Flex>
        <Flex marginTop="5px">
          {fillprice && +fillprice < 50000 ? (
            <Text fontSize={['10px', '', '14px']} color="#FB1919" fontWeight="500">
              Số lượng thiểu trong một giao dịch là 50,000 VND
            </Text>
          ) : null}
        </Flex>
      </Flex>
      <Flex width="100%" justifyContent="center" mt="0.5rem" mb="0.5rem">
        <Flex border={`2px solid ${theme.colors.primaryBright}`} borderRadius="50%">
          <Text width={['20px', '', '']} height={['20px', '', '']}>
            <ChevronDownIcon color="primaryBright" />
          </Text>
        </Flex>
      </Flex>
      <Flex width="100%">
        <Text color="#718096" fontSize={['10px', '', '14px']} fontWeight="800">
          Thực nhận
        </Text>
      </Flex>
      <GridCustom>
        <Flex width="100%" pr={['10px', , '12px']} border={`1px solid ${theme.colors.cardBorder}`} borderRadius="12px">
          <CsNumericFormat
            value={
              selectedToken?.symbol === 'VNDC' ? formatAmount(+fillprice, { notation: 'standard' }) : amountReceived
            }
            decimalScale={2}
            thousandSeparator={!false}
            placeholder={fillprice.length === 0 ? '0.00' : amountReceived}
            // eslint-disable-next-line no-return-assign, no-param-reassign
            onFocus={(e) => (e.target.placeholder = '')}
            maxLength={35}
            allowNegative={false}
            readOnly
          />
        </Flex>
        <Flex width="100%" pl={['10px', , '12px']}>
          <Select options={tokens} defaultOptionIndex={0} onChange={(token) => setSelectedToken(token)} />
        </Flex>
      </GridCustom>
      {+fillprice > 0 && (
        <Text color="#718096" fontSize={['10px', '', '14px']}>
          {formatAmount(+fillprice || 0, { isNormal: true })} VND tương đương{' '}
          {selectedToken?.symbol === 'VNDC'
            ? formatAmount(+fillprice || 0, { notation: 'standard' })
            : formatAmount(+amountReceived, { notation: 'standard' })}
          {selectedToken?.symbol?.trim() === 'VNDC' ? ' điểm ' : ' '}
          {selectedToken?.symbol}
        </Text>
      )}

      {account && (
        <Flex width="100%" mt="0.75rem">
          <Text color="#718096" fontSize={['10px', '', '14px']}>
            Địa chỉ ví:
          </Text>
          &nbsp;
          <Text fontSize={['10px', '', '14px']} fontWeight="800" color="#1A202C">
            {isMobile ? getShortAccount(account) : account}
          </Text>
        </Flex>
      )}

      {user?.isPartner ? (
        <Flex width="100%" justifyContent="center" mt="0.5rem">
          {account ? (
            <>
              {fillprice &&
              +fillprice >= 50000 &&
              selectedToken &&
              !user?.createBlockedTran &&
              !user?.createBlockedTran?.blockedUntil ? (
                <Button width="320px" onClick={handlePay}>
                  Nạp
                </Button>
              ) : (
                <Button width="320px" disabled>
                  Nạp
                </Button>
              )}
            </>
          ) : (
            <ConnectWalletButton width="320px" />
          )}
        </Flex>
      ) : (
        <Partner getPartner={handlePayByPartner} />
      )}
    </Flex>
  )
}

export default DepositFromBlockchain
