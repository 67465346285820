/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-expressions */
// withAuth.tsx
import { authAPI } from 'apis/authAPI'

import { useRouter } from 'next/router'
import { useEffect } from 'react'

const withAuth = (WrappedComponent: React.ComponentType) => {
  const Wrapper: React.FC = (props) => {
    const router = useRouter()
    useEffect(() => {
      const token = localStorage.getItem('token')
      if (!navigator.onLine) {
        return
      }
      if (token) {
        authAPI.me().catch(() => {
          router.push({
            pathname: '/signin', // not router.asPath
            query: { confirm: true },
          })
        })
      } else {
        router.push({
          pathname: '/signin', // not router.asPath
          query: { confirm: true },
        })
      }
    }, [])
    return <WrappedComponent {...props} />
  }
  return Wrapper
}

export default withAuth
