import { useState, useEffect } from "react"
import { getDepayTokensByChain } from "utils/depayTokens"
import { Token } from "@pancakeswap/sdk"
import useActiveWeb3React from "./useActiveWeb3React"

export default function useDepayTokens() {
  const { chainId } = useActiveWeb3React()
  const [tokens, setTokens] = useState<Token[]>([])

  useEffect(() => {
    const fetchTokens = async () => {
      const depayTokens = await getDepayTokensByChain(chainId)
      setTokens(depayTokens)
    };

    fetchTokens()
  }, [chainId])

  return tokens
}

