import { Flex } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import { useState } from 'react'
import styled from 'styled-components'
import { CsNumericFormat, Option, Select } from './style'

const percentage = [25, 50, 75, 100]

export const ButtonSmall = styled.div`
  display: grid;
  place-content: center;
  width: 48px;
  height: 30px;
  font-size: 12px;
  border-radius: 3px;
  background-color: #f1f2f3 !important;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: #1a202c !important;
  font-weight: 600;
  font-size: 12px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const SelectPercent = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`

type InputBalanceProps = {
  max?: number
  value: string
  onChange: (value: string) => void
  decimals?: number
  showSelectPercent?: boolean
  validateMessage?: string
}

const InputBalance = ({ max, value, onChange, showSelectPercent, validateMessage, decimals }: InputBalanceProps) => {
  const { theme } = useTheme()
  const [percentSelect, setPercentSelect] = useState(0)

  function handleChange(v: string) {
    onChange(Number(v).toFixed(decimals || 0))
  }
  const handleChangePercent = (percent: number) => {
    onChange(((max * percent) / 100).toString())
    setPercentSelect(percent)
  }

  return (
    <Flex
      width="100%"
      pr={['10px', , '12px']}
      border={`1px solid ${validateMessage ? '#FF592C' : theme.colors.cardBorder}`}
      position="relative"
      borderRadius="12px"
    >
      <CsNumericFormat
        value={value === '0.00' ? '' : value}
        placeholder="Nhập số tiền muốn nạp"
        onValueChange={(values) => {
          const { value: v } = values
          if (v?.length >= 0) {
            handleChange(v)
          }
        }}
        // eslint-disable-next-line no-return-assign, no-param-reassign
        onFocus={(e: any) => (e.target.placeholder = '')}
        // eslint-disable-next-line no-return-assign, no-param-reassign
        onBlur={(e: any) => (e.target.placeholder = 'Nhập số tiền muốn nạp')}
        decimalScale={decimals}
        fixedDecimalScale
        thousandSeparator
        maxLength={35}
        allowNegative={false}
        name="balance"
      />
      {showSelectPercent && (
        <Flex
          width={['65px', , 'auto']}
          style={{ gap: '8px' }}
          mt={['', , '0px']}
          justifyContent="space-between"
          alignItems="center"
        >
          {percentage.map((percent) => (
            <ButtonSmall key={percent} onClick={() => handleChangePercent(percent)}>
              {percent === 100 ? 'MAX' : `${percent}%`}
            </ButtonSmall>
          ))}
          <SelectPercent>
            <Select value={percentSelect} onChange={(e) => handleChangePercent(+e.target.value)}>
              {percentage.map((percent) => (
                <Option key={percent} value={percent}>
                  {percent === 100 ? 'MAX' : `${percent}%`}
                </Option>
              ))}
            </Select>
          </SelectPercent>
        </Flex>
      )}
    </Flex>
  )
}
export default InputBalance
