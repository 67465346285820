import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 5.71334e-06C15.523 5.71334e-06 20 4.47701 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10H2C2.00015 11.8812 2.66326 13.7023 3.87283 15.1431C5.0824 16.5839 6.76104 17.5524 8.61383 17.8784C10.4666 18.2043 12.375 17.8669 14.0037 16.9254C15.6324 15.984 16.8772 14.4986 17.5194 12.7304C18.1616 10.9622 18.1601 9.02417 17.5152 7.25692C16.8703 5.48967 15.6233 4.00623 13.9931 3.06722C12.363 2.12821 10.4541 1.79372 8.60182 2.12251C6.74954 2.45129 5.07237 3.42233 3.865 4.86501L6 7.00001H0V1.00001L2.447 3.44601C3.38476 2.36304 4.54473 1.49471 5.84805 0.900086C7.15136 0.305458 8.56745 -0.00152729 10 5.71334e-06ZM11 5.00001V9.58501L14.243 12.828L12.828 14.243L9 10.413V5.00001H11Z"
        fill="#194BFB"
      />
    </svg>
  );
};

export default Icon;
