import { useTranslation } from '@pancakeswap/localization'
import { WalletModalV2 } from '@pancakeswap/ui-wallets'
import { Flex, Text } from '@pancakeswap/uikit'
import { partnerApi } from 'apis/partner.api'
import { createWallets, getDocLink } from 'config/wallet'
import { KEY } from 'constants/key.constants'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useActiveHandle } from 'hooks/useEagerConnect.bmp'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useConnect } from 'wagmi'

const TableWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e2e8f0;

  @media screen and (max-width: 768px) {
    border: none;
  }
`

const Table = styled.table`
  width: 100%;
`

const THead = styled.thead`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const TBody = styled.tbody`
  width: 100%;
  & > tr {
    cursor: pointer;
    border-left: 3px solid transparent;
    transition: all 0.2s linear;

    &:hover {
      border-left: 3px solid #194bfb;
      background: #fff;
      box-shadow: 4px 6px 24px 0px rgba(173, 184, 204, 0.16);
    }
  }

  @media screen and (max-width: 768px) {
    & > tr {
      border-radius: 12px;
      cursor: pointer;
      border-left: none;
      transition: all 0.2s linear;

      &:hover {
        border-left: none;
        background: #f5f9fe;
        box-shadow: 4px 4px 18px 0px rgba(103, 103, 103, 0.1);
      }
    }
  }
`

const Th = styled.th`
  color: #718096;
  font-size: 14px;
  font-weight: 800;
  padding: 16px;
  text-align: left;
`

const Tr = styled.tr`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const TrMb = styled.tr`
  width: 100%;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 14px;
  }

  & > td {
    border-top: none;
    padding: 5px 0;
  }

  & > td:last-child {
    padding: 16px 0;
  }
`

const Td = styled.td`
  padding: 16px;
`

const TdMb = styled.td`
  width: 800px;
  padding: 11px 14px !important;
  border-radius: 12px;
  background: rgba(226, 232, 240, 0.1);
`

interface PartnerProps {
    getPartner?: any
}

function Partner({ getPartner }: PartnerProps) {
    const { account } = useActiveWeb3React()
    const handleActive = useActiveHandle()
    const [open, setOpen] = useState(false)
    const { connectAsync } = useConnect()
    const { login } = useAuth()
    const { chainId } = useActiveChainId()
    const [partners, setPartners] = useState<any>([])
    const {
        t,
        currentLanguage: { code },
    } = useTranslation()
    const docLink = useMemo(() => getDocLink(code), [code])

    const handleClick = (item: any) => {
        if (account) {
            getPartner(item)
            return
        }
        if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
            handleActive()
        } else {
            setOpen(true)
        }
    }

    const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])

    useEffect(() => {
        const token = localStorage.getItem(KEY.TOKEN)
        if (token) {
            getPartners()
        } else {
            setPartners([])
        }
    }, [])

    const getPartners = async () => {
        const { data } = await partnerApi.getPartnerList()
        setPartners(data?.data)
    }

    return (
        <>
            <Flex width="100%" flexDirection="column" style={{ gap: '12px' }}>
                <Text fontWeight="800" fontSize={[10, null, 14]} color="#718096">
                    Danh sách các đối tác kinh doanh
                </Text>
                <Text fontSize={[10, null, 14]} fontWeight={500} color="#FB1919">
                    *Lưu ý: Chọn đối tác kinh doanh để tiếp tục thao tác đổi điểm
                </Text>
                <TableWrapper>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Tên đối tác</Th>
                                <Th>Số điện thoại</Th>
                                <Th>Zalo</Th>
                                <Th>Tên ngân hàng</Th>
                                <Th>Số tài khoản</Th>
                                <Th>Tên chủ TK</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {partners?.map((item: any) => (
                                <>
                                    {/* Desktop */}
                                    <Tr onClick={() => handleClick(item)}>
                                        <Td>
                                            <Text color="#1A202C" fontSize={12} fontWeight={700}>
                                                {item?.fullName}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text color="#1A202C" fontSize={12} fontWeight={500}>
                                                {item?.phoneNumber}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text color="#1A202C" fontSize={12} fontWeight={500}>
                                                {item?.zalo}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text color="#1A202C" fontSize={12} fontWeight={500}>
                                                {item?.bankName.length > 18 ? `${item?.bankName.slice(0, 18)}...` : item?.bankName}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text color="#1A202C" fontSize={12} fontWeight={500}>
                                                {item?.accNo}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text color="#1A202C" fontSize={12} fontWeight={500}>
                                                {item?.accName}
                                            </Text>
                                        </Td>
                                    </Tr>
                                    {/* Mobile */}
                                    <TrMb onClick={() => handleClick(item)}>
                                        <TdMb>
                                            <Flex flexDirection="column" style={{ gap: '12px' }}>
                                                <Flex width="100%" justifyContent="space-between">
                                                    <Text fontSize={12} fontWeight={700} color="#718096">
                                                        Tên đối tác
                                                    </Text>
                                                    <Text fontSize={12} fontWeight={800}>
                                                        {item?.fullName}
                                                    </Text>
                                                </Flex>
                                                <Flex width="100%" justifyContent="space-between">
                                                    <Text fontSize={12} fontWeight={700} color="#718096">
                                                        Số điện thoại
                                                    </Text>
                                                    <Text fontSize={12} fontWeight={500}>
                                                        {item?.phoneNumber}
                                                    </Text>
                                                </Flex>
                                                <Flex width="100%" justifyContent="space-between">
                                                    <Text fontSize={12} fontWeight={700} color="#718096">
                                                        Tên ngân hàng
                                                    </Text>
                                                    <Text fontSize={12} fontWeight={500}>
                                                        {item?.bankName.length > 18 ? `${item?.bankName.slice(0, 18)}...` : item?.bankName}
                                                    </Text>
                                                </Flex>
                                                <Flex width="100%" justifyContent="space-between">
                                                    <Text fontSize={12} fontWeight={700} color="#718096">
                                                        Số tài khoản
                                                    </Text>
                                                    <Text fontSize={12} fontWeight={500}>
                                                        {item?.accNo}
                                                    </Text>
                                                </Flex>
                                                <Flex width="100%" justifyContent="space-between">
                                                    <Text fontSize={12} fontWeight={700} color="#718096">
                                                        Tên chủ TK
                                                    </Text>
                                                    <Text fontSize={12} fontWeight={500}>
                                                        {' '}
                                                        {item?.accName}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </TdMb>
                                    </TrMb>
                                </>
                            ))}
                        </TBody>
                    </Table>
                </TableWrapper>
            </Flex>
            <WalletModalV2
                docText={t('Learn How to Connect')}
                docLink={docLink}
                isOpen={open}
                wallets={wallets}
                login={login}
                onDismiss={() => setOpen(false)}
            />
        </>
    )
}

export default Partner
