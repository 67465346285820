import { ButtonMenuItem } from '@pancakeswap/uikit'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

export const Wrapped = styled.div`
  width: 920px;
  border-radius: 40px;
  background: ${({ theme }) => theme.colors.white};
  padding-bottom: 24px;
`
export const CsButtonMenuItem = styled(ButtonMenuItem)`
  font-size: 20px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
    padding: 0px;
  }
`
export const GridExchangeRate = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
  }
  @media screen and (max-width: 425px) {
    grid-row-gap: 14px;
  }
`
export const CardExchangeRate = styled.div`
  background: #fafafa;
  border-radius: 12px;
  width: 100%;
  height: 98px;
  padding: 18px;
  display: grid;
  grid-template-columns: 20% 80%;

  @media screen and (max-width: 425px) {
    padding: 8px;
    height: fit-content;
  }
`

export const CsNumericFormat = styled(NumberFormat)<{ isNotBorder?: boolean }>`
  background-color: transparent;
  outline: none;
  border: none;
  border: ${({ theme, isNotBorder }) => (isNotBorder ? 'none' : theme.colors.text)};
  border-radius: 12px;
  width: 100%;
  height: 56px;
  padding-left: 16px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;

  @media screen and (max-width: 425px) {
    font-size: 12px;
  }

  &:focus-visible {
    outline: none;
  }
  ::placeholder {
    color: '#A0AEC0;
    opacity: 1;
  }
`
export const ContainerActuallyreceived = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  height: auto;
  background: red;
  @media screen and (max-width: 600px) {
    grid-template-columns: 60% 40%;
  }
`

export const ContainerDeposit = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  height: auto;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
  }
`
export const ButtonSmall = styled.div`
  width: 56px;
  height: 28px;
  font-size: 12px;
  border-radius: 3px;
  background-color: #f1f2f3 !important;
  border: none;
  cursor: pointer;
  padding: 19px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

export const SearchCustom = styled.div`
  width: fit-content;
  margin: 20px auto 20px 0;
  position: relative;

  & > input {
    width: 295px;
    padding: 12px 12px 12px 32px;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    font-size: 14px;
    color: #1a202c;
    outline: none;
    background: #ffffff;
  }

  & > input:focus::placeholder {
    color: transparent;
  }

  & > svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #1a202c;
  }

  @media screen and (max-width: 660px) {
    width: 100%;

    & > input {
      width: 100%;
    }
  }
  @media screen and (max-width: 425px) {
    & > input {
      background: unset;
    }
  }
`

export const Select = styled.select`
  padding: 2px 0px 1px 3px;
  background-color: #f1f2f3 !important;
  color: #1a202c;
  font-size: 10px !important;
  border-radius: 2px;
  border: unset;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('/images/depay/select-icon.png');
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  @media (prefers-color-scheme: dark) {
    select::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
`

export const Option = styled.option``
