import { formatUnits } from '@ethersproject/units'
import { NATIVE, NativeCurrency, Token } from '@pancakeswap/sdk'
import {
    AutoRenewIcon,
    Button,
    ChevronDownIcon,
    Flex,
    Input,
    InputGroup,
    SwapExchangeIcon,
    Text,
    useMatchBreakpoints,
    useModal,
    useToast,
} from '@pancakeswap/uikit'
import tryParseAmount from '@pancakeswap/utils/tryParseAmount'
import { exchangeRateApi } from 'apis/exchangeRate.api'
import { transactionApi } from 'apis/transaction.api'
import ConnectWalletButton from 'components/ConnectWalletButton'
import ErrorMessages from 'components/ErrorMessages/ErrorMessage'
import Select from 'components/Select/SelectToken'
import SelectUserBank from 'components/Select/SelectUserBank'
import { IUserBank, LoginContext } from 'contexts/LoginContext'
import { BigNumber } from 'ethers'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import useDebounce from 'hooks/useDebounce'
import { DEPAY_ADDRESS, useDepayDeposit } from 'hooks/useDepayDeposit'
import useDepayTokens from 'hooks/useDepayTokens'
import useTheme from 'hooks/useTheme'
import useTokenBalance from 'hooks/useTokenBalance'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { formatAmount } from 'utils/formatInfoNumbers'
import { getShortAccount } from 'utils/getShortAccount'
import { chains } from 'utils/wagmi'
import AlertErrorNetworkModal from 'views/Signin/components/AlertErrorNetworkModal'
import { useBalance, useSwitchNetwork } from 'wagmi'
import { KEY } from 'constants/key.constants'
import InputBalance from './InputBalance'
import { CsNumericFormat } from './style'

const GridCustom = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  height: auto;
`

function useTokenOrNativeBalance(account: `0x${string}`, token: Token | NativeCurrency) {
    const tokenAddress = token instanceof Token ? token.address : null

    const tokenBalance = useTokenBalance(tokenAddress)
    const nativeBalance = useBalance({ address: account })

    const value = tokenAddress
        ? BigNumber.from(tokenBalance.balance.toString())
        : nativeBalance?.data?.value || BigNumber.from(0)

    const decimals = tokenAddress ? token.decimals : 18

    const symbol = tokenAddress ? token.symbol : nativeBalance?.data?.symbol

    const formatted = tokenAddress ? formatUnits(value, decimals) : nativeBalance?.data?.formatted

    return {
        value,
        decimals,
        symbol,
        formatted,
    }
}

export function useCalculateAmountReceived(amount: number, fromSymbol: string, toSymbol: string) {
    const [amountReceived, setAmountReceived] = React.useState(0)
    const debouncedSearchTerm = useDebounce(amount, 600)

    console.log('amount', amount)
    async function fetchExchangeRate() {
        try {
            const res = await exchangeRateApi.getExchangeRate({ amount, from: fromSymbol, to: toSymbol })
            setAmountReceived(res.amountReceived)
        } catch (error) {
            setAmountReceived(0)
        }
    }

    useEffect(() => {
        const token = localStorage.getItem(KEY.TOKEN)
        if (token) {
            if (debouncedSearchTerm) {
                fetchExchangeRate()
            }
        }
    }, [debouncedSearchTerm, fromSymbol, toSymbol])

    return amountReceived
}

const DepositToBank = ({ account }: { account: `0x${string}` }) => {
    const { isMobile } = useMatchBreakpoints()
    const { theme } = useTheme()
    const toast = useToast()
    const { user }: any = useContext(LoginContext)
    const options = user?.banks || []
    const tokens = useDepayTokens()
    const [loading, setLoading] = React.useState(false)
    const { push } = useRouter()

    const [networkError, onDismiss] = useModal(
        <AlertErrorNetworkModal title="Bị lỗi mạng" subTitle="Vui lòng kiểm tra kết nối của bạn và thử lại sau." />,
    )

    const [selectedToken, setSelectedToken] = React.useState<Token | NativeCurrency>(tokens[0])
    const [selectedBank, setSelectedBank] = React.useState<IUserBank>(null)

    const balance = useTokenOrNativeBalance(account, selectedToken)

    const [depositAmount, setDepositAmount] = React.useState('')

    const receivedAmount = useCalculateAmountReceived(+depositAmount, balance?.symbol, 'VND')

    const isNativeCurrency = selectedToken instanceof NativeCurrency

    const parsedDepositInputAmount = useMemo(
        () => tryParseAmount(depositAmount, selectedToken || undefined),
        [depositAmount, selectedToken],
    )

    const { chainId } = useActiveChainId()
    const { pendingChainId, isLoading } = useSwitchNetwork()

    const foundChain = useMemo(
        () => chains.find((c) => c.id === (isLoading ? pendingChainId || chainId : chainId)),
        [isLoading, pendingChainId, chainId],
    )
    const symbol = NATIVE[foundChain?.id]?.symbol ?? foundChain?.nativeCurrency?.symbol

    const [approvalState, approveACallback] = useApproveCallback(parsedDepositInputAmount, DEPAY_ADDRESS)
    const depayDeposit = useDepayDeposit()

    const refesh = () => {
        setSelectedBank(null)
        setDepositAmount('')
    }

    const handleDeposit = async () => {
        if (!navigator.onLine) {
            networkError()
            return
        }
        if (!selectedToken) return
        if (isNativeCurrency) {
            toast.toastError('Native currency is not supported yet')
            return
        }

        if (approvalState === ApprovalState.NOT_APPROVED) {
            approveACallback()
            return
        }

        setLoading(true)

        try {
            const withdraw = await transactionApi.createWithdraws({
                amount: +depositAmount,
                from: selectedToken.symbol,
                to: 'VND',
                walletAddress: account,
                chainId: selectedToken.chainId,
                bank: {
                    bankNo: selectedBank.bankNo,
                    accNo: selectedBank.accNo,
                    accName: selectedBank.accName,
                },
            })

            const transactionId = withdraw.data.transaction.id
            push({ query: { tx: transactionId } })
            if (!transactionId) {
                toast.toastError('Cannot create transaction')
                return
            }
            await depayDeposit(selectedToken, +depositAmount, transactionId)
            refesh()
        } catch (error) {
            console.error(error)
            toast.toastError('Có lỗi xảy ra')
        } finally {
            setLoading(false)
        }
    }

    const validateAmountMessage = useMemo(() => {
        const max = balance?.formatted
        if (max && +depositAmount > +max) {
            return 'Số dư không đủ'
        }
        return ''
    }, [balance, depositAmount])

    const canSend =
        +depositAmount !== 0 &&
        selectedBank &&
        +receivedAmount !== 0 &&
        !validateAmountMessage &&
        !user?.createBlockedTran &&
        !user?.createBlockedTran?.blockedUntil

    useEffect(() => {
        if (tokens.length > 0) {
            setSelectedToken(tokens[0])
        }
    }, [tokens])

    return (
        <Flex width="100%" flexDirection="column" style={{ gap: '12px' }}>
            <Flex width="100%" justifyContent="space-between" alignItems="center" margin="20px 0">
                <Flex alignItems="center" style={{ gap: '8px' }}>
                    <Text fontWeight="800" fontSize={[13, null, 20]} color="#1A202C">
                        Quy đổi {selectedToken?.symbol} - Mạng {foundChain?.name}
                        {selectedBank?.bankName && ` - ${selectedBank?.bankName || ''}`}
                    </Text>
                </Flex>

                <Flex justifyContent="flex-end">
                    <Link href="/transaction" passHref>
                        <Flex alignItems="center" style={{ gap: '10px', cursor: 'pointer' }}>
                            <SwapExchangeIcon width="28px" />
                            <Text fontSize={['14px', '', '16px']} fontWeight="500" color="#194BFB">
                                Lịch sử
                            </Text>
                        </Flex>
                    </Link>
                </Flex>
            </Flex>
            <Flex width="100%" marginTop="40px">
                <Text color="#718096" fontSize={['10px', '', '14px']} fontWeight="800">
                    Số tiền cần nạp
                </Text>
            </Flex>
            <GridCustom>
                <Flex width="100%" flexDirection="column">
                    <InputBalance
                        value={
                            depositAmount === '0.00'
                                ? ''
                                : selectedToken?.symbol !== 'VNDC'
                                    ? formatAmount(+depositAmount || 0, { notation: 'standard' })
                                    : formatAmount(+depositAmount || 0, { isNormal: true })
                        }
                        onChange={(v) => {
                            setDepositAmount(v)
                        }}
                        max={+balance?.formatted}
                        showSelectPercent
                        decimals={2}
                        validateMessage={validateAmountMessage}
                    />
                    <ErrorMessages message={validateAmountMessage} />
                </Flex>
                <Flex width="100%" maxWidth={[130, null, 150]} pl={['0px', , '12px']}>
                    <Select options={tokens} defaultOptionIndex={0} onChange={setSelectedToken} />
                </Flex>
            </GridCustom>

            <Flex flexDirection="column">
                {account && (
                    <Flex width="100%" marginBottom="5px">
                        <Text color="#718096" fontSize={['10px', '', '14px']}>
                            Địa chỉ ví:
                        </Text>
                        &nbsp;
                        <Text fontSize={['10px', '', '14px']} fontWeight="800" color="#1A202C">
                            {isMobile ? getShortAccount(account) : account}
                        </Text>
                    </Flex>
                )}

                <Flex width="100%" marginBottom="5px">
                    <Text color="#718096" fontSize={['10px', '', '14px']}>
                        Số dư:
                    </Text>
                    &nbsp;
                    <Text color="#1A202C" fontSize={['10px', '', '14px']} fontWeight="800" marginLeft={20}>
                        {formatAmount(+balance?.formatted, { notation: 'standard' })}
                        &nbsp;
                        {selectedToken?.symbol}
                    </Text>
                </Flex>
            </Flex>

            <Flex width="100%" justifyContent="center" mt="0.5rem" mb="0.5rem">
                <Flex border={`2px solid ${theme.colors.primaryBright}`} borderRadius="50%">
                    <Text width={['20px', '', '']} height={['20px', '', '']}>
                        <ChevronDownIcon color="primaryBright" />
                    </Text>
                </Flex>
            </Flex>

            <Flex width="100%">
                <Text color="#718096" fontSize={['10px', '', '14px']} fontWeight="800">
                    Thực nhận
                </Text>
            </Flex>
            <Flex width="100%" pr={['10px', , '12px']} border={`1px solid ${theme.colors.cardBorder}`} borderRadius="12px">
                <InputGroup endIcon={<Text fontSize={['12px', '', '16px']}>VND</Text>} scale="lg">
                    <CsNumericFormat
                        value={+depositAmount !== 0 ? (selectedToken?.symbol !== 'VNDC' ? receivedAmount : +depositAmount) : 0}
                        decimalScale={2}
                        thousandSeparator={!false}
                        // eslint-disable-next-line no-return-assign, no-param-reassign
                        onFocus={(e) => (e.target.placeholder = '')}
                        maxLength={35}
                        allowNegative={false}
                        readOnly
                    />
                </InputGroup>
            </Flex>

            {+depositAmount > 0 && (
                <Text color="#718096" fontSize={['10px', '', '14px']}>
                    {formatAmount(+depositAmount, { notation: 'standard' })}{' '}
                    {selectedToken?.symbol?.trim() === 'VNDC' ? 'điểm' : ''} {selectedToken?.symbol} tương đương{' '}
                    {selectedToken?.symbol?.trim() === 'VNDC'
                        ? formatAmount(+depositAmount, { isNormal: true })
                        : formatAmount(+receivedAmount, { isNormal: true })}{' '}
                    VND
                </Text>
            )}

            <Flex width="100%" justifyContent="space-between">
                <Text color="#718096" fontSize={['10px', '', '14px']} fontWeight="800">
                    Chuyển tiền đến
                </Text>
                <Text
                    color="primaryBright"
                    fontSize={['10px', '', '14px']}
                    fontWeight="800"
                    style={{ cursor: 'pointer' }}
                    onClick={() => push('/add-bank')}
                >
                    Thêm ngân hàng
                </Text>
            </Flex>
            <Flex width="100%">
                <SelectUserBank selected={selectedBank} onChange={setSelectedBank} />
            </Flex>
            <Flex width="100%">
                {options?.length === 0 && (
                    <Text fontSize={['10px', '', '14px']} fontWeight="500" color="#FB1919">
                        {`Bạn chưa có tài khoản ngân hàng. Nhấn vào liên kết "Thêm ngân hàng" để liên kết tài khoản`}
                    </Text>
                )}
            </Flex>
            <Flex width="100%" justifyContent="space-between">
                <Text color="#718096" fontSize={['10px', '', '14px']} fontWeight="800">
                    Tên chủ tài khoản
                </Text>
            </Flex>
            <Flex width="100%">
                {selectedBank?.accName ? <Input disabled value={`${selectedBank?.accName}`} /> : <Input value="" disabled />}
            </Flex>
            <Flex width="100%">
                {options?.length > 0 && (
                    <>
                        {selectedBank?.accNo && (
                            <>
                                <Text color="#718096" fontSize={['10px', '', '14px']}>
                                    Số tài khoản:
                                </Text>
                                &nbsp;
                                <Text color="#1A202C" fontSize={['10px', '', '14px']} fontWeight="800">
                                    {selectedBank?.accNo}
                                </Text>
                            </>
                        )}
                    </>
                )}
            </Flex>
            <Flex width="100%" justifyContent="center" mt="0.5rem">
                {account ? (
                    <Button
                        width="320px"
                        onClick={handleDeposit}
                        disabled={!canSend || loading || approvalState === ApprovalState.PENDING}
                    >
                        {loading && <AutoRenewIcon spin color="currentColor" mr="8px" />}

                        {approvalState === ApprovalState.PENDING && 'Approving ...'}
                        {approvalState === ApprovalState.NOT_APPROVED && 'Approve'}
                        {approvalState === ApprovalState.APPROVED && 'Nạp'}
                        {approvalState === ApprovalState.UNKNOWN && 'Nạp'}
                    </Button>
                ) : (
                    <ConnectWalletButton width="320px" />
                )}
            </Flex>
        </Flex>
    )
}

export default DepositToBank
