import axios from 'axios'

export type Withdraw = {
  success: true
  data: {
    transaction: {
      id: string
      type: 'withdraw' | 'topup'
      status: string
    }
  }
}

const createTopUps = async (data: {
  amount: number
  from: string
  to: string
  walletAddress: string
  chainId: number
  bankBin?: number
  accNo?: number
  accName?: string
  partnerId?: string
}) => (await axios.post('transactions/topups', data)).data

const createTopUpsIsPartner = async (data: {
  amount: number
  from: string
  to: string
  walletAddress: string
  chainId: number
  bankBin?: number
  accNo?: number
  accName?: string
}) => (await axios.post('transactions/topups/partners', data)).data

const createWithdraws = async (data: {
  amount: number
  from: string
  to: string
  walletAddress: string
  chainId: number
  bank: {
    bankNo: string
    accNo: string
    accName: string
  }
}) => (await axios.post<Withdraw>('transactions/withdraws', data)).data

const getTransactions = async (query: any) => {
  return axios.get('/transactions', { params: query })
}

const getTransactionsPartner = async (query: any) => {
  return axios.get('/transactions/topups/partners', { params: query })
}

const cancelTransactions = async (payload: any) => {
  return axios.patch('/transactions/cancel', payload)
}

const failureTransactions = async (payload: any) => {
  return axios.patch('/transactions/failure', payload)
}

const transactionVerify = async () => {
  return axios.get('/transactions/transfer-verification')
}

const getQr = async (tranId: any) => {
  return axios.get(`/transactions/qr/${tranId}`)
}

export const transactionApi = {
  createWithdraws,
  createTopUps,
  getTransactions,
  cancelTransactions,
  failureTransactions,
  transactionVerify,
  getQr,
  createTopUpsIsPartner,
  getTransactionsPartner,
}
