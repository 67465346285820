import { ChevronDownIcon, Flex, Text, useModal } from '@pancakeswap/uikit'
import { IUserBank, LoginContext } from 'contexts/LoginContext'
import { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const DropDownHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  transition: border-radius 0.15s;
  @media screen and (max-width: 600px) {
    padding: 0 10px;
  }
`

const DropDownListContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 250px;
  margin-top: 1rem;
  padding: 12px;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  box-sizing: border-box;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.background};
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 50px;
    width: 100%;
  }
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number }>`
  cursor: pointer;
  width: ${({ width }) => width}px;
  position: relative;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 12px;
  ${(props) =>
    props.isOpen &&
    css`
      ${DropDownHeader} {
        /* box-shadow: ${({ theme }) => theme.tooltip.boxShadow}; */
      }

      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border-top-width: 0;
        border: 1px solid ${({ theme }) => theme.colors.cardBorder};
        width: 100%;
        background: ${({ theme }) => theme.colors.background};
        top: 49px;
        border-radius: 8px;
      }
    `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;
  border-radius: 6px;
  &:hover {
    background: rgba(255, 255, 255, 0.06);
    > div {
      color: ${({ theme }) => theme.colors.text} !important;
    }
  }
`

export interface SelectProps {
  onChange?: (option: IUserBank) => void
  selected?: IUserBank
}

const SelectUserBank: React.FunctionComponent<SelectProps> = ({ onChange, selected }) => {
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  const { user } = useContext(LoginContext)
  const options = user?.banks || []

  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(!isOpen)
    event.stopPropagation()
  }

  const onOptionClicked = (selectedIndex: number) => () => {
    setIsOpen(false)

    if (onChange) {
      onChange(options[selectedIndex])
    }
  }

  const handleClickOutside = () => {
    if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    setContainerSize({
      width: dropdownRef.current.offsetWidth, // Consider border
      height: dropdownRef.current.offsetHeight,
    })

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <DropDownContainer isOpen={isOpen} ref={containerRef} {...containerSize}>
      <DropDownHeader onClick={toggling}>
        <Flex alignItems="center" style={{ gap: '12px' }}>
          {selected ? (
            <Text fontSize={['12px', '', '16px']} textTransform="uppercase" color="text">
              {selected?.bankName} - <span style={{ fontWeight: '800', color: '#1A202C' }}>{selected?.accNo}</span>
            </Text>
          ) : (
            <Text fontSize={['12px', '', '16px']} color="#718096">
              Chọn ngân hàng
            </Text>
          )}
        </Flex>
      </DropDownHeader>
      <ChevronDownIcon onClick={toggling} />
      <DropDownListContainer>
        <DropDownList ref={dropdownRef}>
          {options.map((option, index) => (
            <CustomListItem onClick={onOptionClicked(index)} key={option.id}>
              <Flex width="100%" justifyContent="flex-start" alignItems="center" style={{ gap: '12px' }}>
                <Text fontSize={['12px', '', '16px']}>
                  {option?.bankName} - <strong>{option?.accNo}</strong>
                </Text>
              </Flex>
            </CustomListItem>
          ))}
          {options.length === 0 && (
            <CustomListItem>
              <Flex width="100%" justifyContent="flex-start" alignItems="center" style={{ gap: '12px' }}>
                <Text fontSize={['12px', '', '16px']}>No data found</Text>
              </Flex>
            </CustomListItem>
          )}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>
  )
}

export default SelectUserBank

const CustomListItem = styled(ListItem)`
  @media screen and (max-width: 320px) {
    padding: 0px;
  }
`
